.subContatto{
    padding:20px;
    font-size:1.4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin:20px;
    box-sizing: border-box;
}
.contactButton{
    width: 90%;
    display: flex;
    margin: auto;
    margin-bottom:40px;
    box-sizing: border-box;
}

.contactButton a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: rgba(0, 128, 0, 0);
    font-weight: bold;
    padding:10px;
    font-size:1rem;
    margin:auto;
    border-radius: 20px;
    border:2px solid rgb(0, 0, 0);
    box-shadow: 2px 2px 5px rgb(115, 115, 115);
    transition: color 200ms, background-color 200ms, box-shadow 200ms;
}


.contactButton a:hover{
    background-color: rgb(255, 255, 255);
}

.subContattoDesc{
    font-weight: bold;
}

form{
    background-color: rgba(0, 0, 0, 0.8);
    color:white;
    padding:20px;
    margin:20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    box-sizing: border-box;
    margin-bottom:50px;
}

form label{
    margin-bottom:20px;
    margin-top:20px;
    font-weight: bold;
}

form input{
    box-sizing: border-box;
    padding:15px;
    font-size:1.3rem;
    outline:none;
    width: 100%;
    border-radius: 5px;
}

form input[type="button"]{
    margin-top:30px;
    background-color: black;
    color:white;
    font-weight: bold;
    border:1px solid white;
    transition:color 200ms, background-color 200ms;
}

form input[type="button"]:hover{
    cursor:pointer;
    color:black;
    background-color:white;
}