#banner-discount{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin: auto;
    width: min(400px,80%);
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    border: 5px solid rgb(255, 255, 255);
    color:white;
    border-radius: 20px;
    left:0;
    right:0;
    top:90px;
    animation: appari-sconto 500ms forwards;
}

#banner-discount.close{
    display: none;
}

#banner-discount #desc{
    color: rgb(255, 255, 255);
    font-size: 15px;
    margin-top: 10px;
    width: 90%;
    text-align: center;
}


#banner-discount #info{
    color: rgb(195, 195, 195);
    font-size: 12px;
    margin-top: 10px;
    padding-bottom: 20px;
}

#banner-discount > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-top: 10px;
}

#banner-discount > div > h1{
    padding: 0;
    margin: 0;
    flex:1;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    font-size: 18px;
    text-align: left;
}

#banner-discount .icon{
    cursor: pointer;
}

@keyframes appari-sconto {
    from{opacity:0;transform: scale(0);}
    to{top:130px;opacity:1;transform: scale(1)}
}