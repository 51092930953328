.doveSiamo{
    overflow: hidden;
}

.doveSiamo .img-video{
    position: relative;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap:25px;
    justify-content: center;
    align-items: center;
    height: 270px;
    width: min(600px,90%);
    overflow-x: hidden;
    animation: appari 300ms forwards;
}

@media (max-width: 991.98px){
    .doveSiamo .img-video{
        margin: auto;
    }

}

@media (min-width: 768px){
    .doveSiamo .img-video{
        height: 450px;
    }
    #video{
        height: 400px;
    }
}

.doveSiamo .img-video::-webkit-scrollbar{
    width: 0;
}

.doveSiamo .card{
    height: fit-content;
    width: 80%;
}

.doveSiamo .img-video :is(video,img){
    display: block;
    position: relative;
    border-radius: 10px;
    height: 100%;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.info{
    margin-top:50px;
    width:100%;
    display: flex;
    flex-direction: column;
}

.info ul{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.subInfo{
    font-size: 1rem;
    width: 85%;
    margin: auto;
    margin-bottom:20px;
    display: flex;
    align-items: center;
}

.subInfo.orario{
    display: flex;
    align-items: flex-start;
    justify-content: left;
}
.subInfo.orario > div{
    display: flex;
    align-items: center;
    margin-top: 0;
    justify-self: left;
}
.subInfo.orario span{
    display: block;
}
.subInfo.orario ul{
    margin-top: 9px;
    margin-left: 0;
    padding-left: 0;
}

.subInfo span{
    margin-left:10px;
    margin-right:0px;
    font-weight: bold;
}

.subInfo i{
    width:30px;
}

