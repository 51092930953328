#cart{
    position: fixed;
    background-color: white;
    bottom:0;
    width: 100%;
    height: 95%;
    border-radius: 30px 30px 0px 0px;
    z-index: 1;
    animation: popup-appari 300ms forwards;
}

#cart .head{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    border-bottom: 1px solid grey;
}

#cart .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75%;
    min-height: inherit!important;
    overflow: auto;
}

@media (min-width:768px){
    #cart{
        width: 70%;
        max-width: 700px;
        bottom:100px!important;
        height: 70%;
        border-radius: 30px;
        padding-bottom: 10px;
    }
    #cart .content{
        height: 70%;
    }
}