.scontrinoElements{
    width:70%;
    height: 25%;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    color:white;
    padding:20px;
    margin-top:30px;
    margin-bottom:20px;
    position: relative;
    overflow: auto;
}
#copiaButton{
    margin:0;
    position: absolute;
    top:0;
    right:0;
    text-align: center;
    background-color: white;
    color:black;
    font-weight: bold;
    padding:6px;
    font-size:1rem;
    width: 90px;
    outline:none;
    border:1px solid black;
    border-radius: 0px 10px 0px 10px;
    transition: background-color 300ms;
}
#copiaButton:disabled{
    background-color: rgb(120, 120, 120);
    opacity: 30;
}
.scontrino #contact div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    color:white;
    font-weight: bold;
    border-radius: 20px;
    width: fit-content;
    padding:15px 20px;
    gap:10px;
}

.scontrino .totale{
    font-weight: bold;
    font-size:1.3rem;
    text-align: center;
    margin-top: 30px;
}
.scontrino .totale span{
    padding:5px;
}
@media (min-width: 768px){
    .scontrino{
        width:50%
    }
}