#banner{
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background-color: rgb(33, 33, 33);
    color:white;
    font-size: 8px;
    text-align: center;
    margin-bottom: 10px;
}

#banner span{
    font-weight: bold;
}