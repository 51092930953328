
.termini div{
    text-align: left;
    width: 80%;
    margin: auto;
}
.termini h4{
    text-align: center;
    font-size: 1.5rem;
}
.termini p{
    text-align: left;
    font-size: 1.3rem;
}
.termini ul{
    font-size: 1.3rem;
}