.navbar{
    width:100%;
    position: sticky;
    top:0;
    left:o;
    right:0;
    background-color: black;
    padding:20px;
    box-sizing: border-box;
    z-index:10;
}

.navFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navFlex .logo{
    font-size:1.4rem;
}
.navFlex .icons{
    display: flex;
}

.navFlex .icons img{
    width: 27px;
    height: 27px;
}

.navbar .logo{
    display: flex;
    color:white;
    align-items: center;
}

.navbar .logo img{
    width:10vw;
    max-width: 80px;
    margin-right:2vw;
}

.menu{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 0px;
    transition: height 800ms, margin-top 1.5s;
}

.menu a{
    color:rgb(255, 255, 255);
    text-decoration: none;
    transition: background-color 200ms;
    padding:20px;
    font-size:1rem;
    border-bottom:1px solid rgba(255, 255, 255, 0.4);
    transition: all 300ms;
    position: relative;
}

.menu a:last-child{
    border: none;
}

.menu a.active{
    font-weight: bold;
}

.menu a:hover{
    font-weight: bold;
}

@media (min-width: 900px){
    .navbar{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbar .hamburger{
        display:none;
    }
    .navbar .icons{
        position: fixed;
        right:50px;
        bottom:50px;
        background-color: black;
        border:2px solid white;
        justify-content: center;
        padding:20px;
        border-radius: 20px;
    }
    .navbar .icons .icon{
        margin:0!important;
    }
    .menu{
        height: fit-content!important;
        flex-direction: row;
        margin:0!important;
    }
    .menu a{
        border:none;
        border-right:1px solid rgba(255, 255, 255, 0.4);
    }
    .menu a:last-child{
        border: none;
    }
}