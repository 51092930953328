#social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

#social h3{
    text-align: center;
    margin-top: 20px;
    padding: 20px;
}

#social .social{
    display: flex;
    gap:40px;
    margin-top: 0px;
    margin-bottom: 60px;
}

#social .social a img{
    width: 50px;
    height: 50px;
}

#social .logo{
    width: min(300px,80%);
    margin-top: 40px;
}

#social #ig,#social #fb{
    position: relative;
    height: 50px;
}

#social #ig::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #405de6, #5851db, #833ab4, #c13584);
    filter: blur(10px);
    z-index: -1;
    border-radius: 30px;
    opacity: 0.7;
    animation: pulsa-gira 1.3s infinite
}

#social #fb::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #96b7ff, #006fd0);
    filter: blur(15px);
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
    animation: pulsa-gira 1.3s infinite
}

@keyframes pulsa-gira {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.2) rotate(180deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}