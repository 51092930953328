.prodotto{
    background-color: white;
    color:black;
    width:100%;
    box-sizing: border-box;
    margin:auto;
    padding:30px;
    font-size:1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px black;
}

.prodotto .nome{
    font-weight: bold;
    font-size:1.1rem;
}

.prodotto .icon{
    width: 35px;
}

.flexProd{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}