.infoscreen{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    background-color: white;
    color:black;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: screen-appari 700ms;
}

.infoscreen .chiudi{
    position: absolute;
    right:20px;
    top:20px;
}

.infoscreen .logo{
    margin-top: 60px;
    margin-bottom: 15px;
}

.infoscreen input[type="button"]{
    margin-top: 50px;
}

.boxes{
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 35px;
    width: 80%;
    box-sizing: border-box;
}

.inputbox{
    display:flex;
    align-items: center;
    width: 100%;
    gap:5px;
    margin:0;
    box-sizing: border-box;
}

.inputbox b{
    width: 30%;
}

@media (min-width: 768px){
    .infoscreen{
        position: fixed;
        width: 70%!important;
        max-width: 750px!important;
        height: 70%!important;
        border-radius: 30px;
        padding-bottom: 50px;
    }
    .infoscreen .logo{
        display: none;
    }
    .infoscreen h2{
        margin-top: 50px;
    }
}