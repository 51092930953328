#popup-cont{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: appari-popup 600ms forwards;
}

#popup-social{
    position: relative;
    background-color: white;
    padding:30px;
    box-sizing: border-box;
    width: min(400px,80%);
    border-radius: 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.441);
}
#popup-social h5{
    padding-left: 5px;
    margin: 0;
    margin-bottom: 20px;
}

#popup-social .social{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    gap:15px;
    justify-content: space-evenly;
}

#popup-social .social a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 13px;
}

#popup-social .social a img{
    width: 30px;
    height: 30px;
}


#popup-social .social a span{
    width: 70%;
}

#popup-social .icon.chiudi{
    position: absolute;
    top:20px;
    right: 25px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

#popup-cont.close{
    display: none;
}

@keyframes appari-popup {
    from{opacity: 0;}
    to{opacity: 1;}
}