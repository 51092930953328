.prodottoCarrello{
    background-color: white;
    color:black;
    width:90%;
    box-sizing: border-box;
    padding:30px;
    padding-bottom: 0px;
    font-size:1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.prodottoCarrello::after{
    content:"";
    display: block;
    margin-top: 30px;
    height: 1px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.25);
}

.prodottoCarrello .nome{
    font-weight: bold;
    font-size:1.1rem;
}

.prodottoCarrello .addRemoveButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.prodottoCarrello .addRemoveButton span{
    border:1px solid grey;
    border-radius: 10px;
    padding:5px;
    width: 30px;
    text-align: center;
}

.prodottoCarrello .addRemoveButton .icon{
    width: 25px;
    height: 25px;
}

.prodottoCarrello .flexProd{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}