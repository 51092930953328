
.card{
    margin:auto;
    border-radius:20px;
    background-color: white;
    padding:20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card h2{
    margin-bottom:0px;
    padding:10px;
    padding-top:0px;
    margin-top:0px;
    font-size: 1.4rem;
    text-align: center;
}
.card p{
    margin:0px;
    margin: auto;
    padding: 0;
    text-align: center;
}
.card a{
    padding:10px;
    background-color: black;
    color:white;
    border-radius:10px;
    text-align: center;
    min-width:200px;
    transition: min-width 300ms;
}

.card.vieni{
    font-size:1.5rem;
    font-weight: bold;
    margin-top:30px;
    margin-bottom:50px;
    transition: background-color 500ms;
}

.card.vieni a{
    color:black;
    background-color: rgba(0, 0, 0, 0);
    width: fit-content;
    margin:0;
}

.card.vieni:hover{
    background-color: rgba(0, 0, 0, 0);
}
