#alert{
    position: fixed;
    display: flex;
    justify-content: center;
    top:120px;
    width:100%;
    box-sizing: border-box;
    z-index: 999;
    animation: appari 300ms forwards;
}

#alert div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;
    text-align: center;
    color:white;
    border-radius: 10px;
    background-color: black;
    font-weight: bold;
    box-shadow: 5px 5px 1px 2px rgba(0, 0, 0, 0.244);
}