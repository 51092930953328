#info-legal{
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    gap:7px;
    grid-column-start: 1;
    grid-column-end: 3;
}

#info-legal div{
    font-size: 13px!important;
}