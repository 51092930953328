body {
  background-color: #e4e4e4;
  margin: 0;
  font-family: "Montserrat",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before{
  content:"";
  position: fixed;
  top:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #00000080 20%, transparent 21%, transparent 79%, #00000080 80%) , linear-gradient(315deg, #00000080 20%, transparent 21%), linear-gradient(45deg, #000000 20%, transparent 21%), linear-gradient(315deg, #000000 20%, transparent 21%, transparent 79%, #00000080 80%);
        background-size: 6em 6em;
        background-color: #ffffff;
        opacity: 0.05;
  z-index:-1;
}

/* GENERIC */

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #e2e2e2; 
}

::-webkit-scrollbar-thumb {
  background: rgb(88, 88, 88); 
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49); 
}

*{
  -webkit-tap-highlight-color: transparent;
}

h1{
  font-size:1.6rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color:white;
  padding:20px;
  box-shadow: 3px 0px 9px rgb(0, 0, 0);
}

.icon{
  width: 35px;
  height: 35px;
  filter: invert(95%) sepia(5%) saturate(18%) hue-rotate(87deg) brightness(104%) contrast(104%);
}

.icon.black{
  filter: none;
}

p{
  text-align: center;
  padding:20px;
  font-size:1.4rem;
}
a{
  text-decoration: none;
  color:inherit
}

.popup-cont{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.174);
  animation: sfoca 300ms forwards;
}

input[type="button"]{
  display: block;
  border:2px solid black;
  outline:none;
  background-color: rgb(255, 255, 255);
  padding: 10px 30px 10px 30px;
  font-weight: bold;
  border-radius: 10px;
}
input[type="button"]:hover{
  background-color: rgb(231, 231, 231);
}

select{
  display: block;
  width: 100%;
  border:1px solid rgba(0, 0, 0, 0.553);
  outline:none;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
}

input[type="text"]{
  display: block;
  border:none;
  width: 100%;
  border-bottom:2px solid rgba(0, 0, 0, 0.373);
  outline:none;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  font-size: 15px;
}

input[type="date"]{
  display: block;
  border:none;
  width: 100%;
  border-bottom:2px solid rgba(0, 0, 0, 0.373);
  outline:none;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  font-size: 15px;
}

input[type="time"]{
  display: block;
  border:none;
  width: 100%;
  border-bottom:2px solid rgba(0, 0, 0, 0.373);
  outline:none;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  font-size: 15px;
}

@keyframes sfoca {
  0%{backdrop-filter: blur(0px);    background-color: rgba(0, 0, 0, 0);}
  100%{backdrop-filter: blur(3px);    background-color: rgba(0, 0, 0, 0.2);}
}

@keyframes popup-appari {
  0%{bottom:-100%;}
  100%{bottom:0;}
}

@keyframes appari {
  0%{opacity:0;transform: scale(0);}
  100%{opacity:1;transform: scale(1);}
}

@keyframes screen-appari{
  0%{opacity: 0;height: 0px;}
  100%{opacity: 100;height: 100%;}
}

.content{
  min-height: 70vh;
}

@media (min-width:768px){
  .content h1{
    border-radius: 30px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
  .icon{
    cursor:pointer;
  }
  .doveSiamo >div{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    gap:50px;
    justify-content: center;
  }
  .doveSiamo>div>div{
    width: fit-content;
    min-width: 400px;
  }
  .offerteNotizie>div{
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: center;
    gap:20px;
    flex-wrap: wrap;
  }
  .offerteNotizie .offerte,.offerteNotizie .notizie{
    width: 40%;
    min-width: 500px;
  }
  .offerteNotizie .offerta{
    border-radius: 30px;
  }
  .prodotti>div{
    margin: auto;
    width: 80%;
    max-width: 800px;
  }
  .banner{
    display: none!important;
  }
}

@media (min-width:1068px){
  #flex-home{
    display: flex;
    margin: auto;
    margin-top:30px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap:50px
  }
  #flex-home .video-cont{
    margin:0;
    width: 600px;
    margin-bottom: 50px;
  }
  #flex-home .video-cont video{
    width: 100%;
  }
}