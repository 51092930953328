.footer{
    background-color: black;
    width:100%;
    padding:20px;
    padding-bottom: 30px;
    box-sizing: border-box;
    display: grid;
    row-gap: 5%;
    column-gap: 9%;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2,40%);
    grid-template-rows: repeat(3,auto);
    box-sizing: border-box;
}

.footer .logo{
    display: flex;
    color:white;
    align-items: center;
    box-sizing: border-box;
}

.footer .logo img{
    width:10vw;
    margin-right:2vw;
}

.footer .social{
    margin-top:20px;
    display: flex;
    align-items: center;
    text-decoration:none;
    border-left:2px solid white;
    padding:10px;
    box-sizing: border-box;
}

.footer .social a{
    font-size:1.5rem;
    color:white;
    margin-right:12px;
}

.footer .social img{
    width: 25px;
    height: 25px;
}

.footer .info{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: center;
    box-sizing: border-box;
}

.footer .info a{
    text-decoration: none;
    color:white;
}

.footer .info a{
    display: flex;
    margin-bottom:10px;
    flex-direction: column;
    text-decoration: none;
    color:white;
}

.footer #banner{
    grid-column-start: 1;
    grid-column-end: 3;
}

@media (min-width: 768px){
    .footer .logo img{
        width: 15%;
    }
    .footer div{
        font-size:1.6rem;
    }
    #info-legal{
        font-size: 10px!important;
    }
    .footer .social a{
        font-size: 1.7rem;
    }
    .footer .info a{
        font-size: 1.4rem;
    }
    .info a:hover{
        text-decoration: underline;
    }
}