#recensione{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    width: 100%;
    margin-bottom: 40px;
}

#recensione .star{
    width: 30px;
    height: 30px;
}

#recensione #links{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap:10px;
}

#recensione a{
    display: block;
    width: 30px !important;
    height: 30px !important;
}

#recensione .logo-rec{
    width: 100%;
    height:100%;
}