#allerg_btn{
    background: linear-gradient(45deg,black,grey);
    padding: 10px 5px 10px 5px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    width: 330px;
    text-align: center;
    margin: auto;
    margin-top: 30px;
}
