
.dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left:25px;
    padding-right:25px;
    box-sizing: border-box;
    margin: auto;
    background: linear-gradient(45deg, rgb(245, 245, 245) 65%, rgba(221, 233, 232, 0) 30%);
    animation:appari 300ms forwards;
}

.dropdown .icon{
    cursor: initial;
}

.dropdown.inner{
    border-radius: 20px!important;
    margin-bottom: 10px;
}

.dropdown.inner h2{
    border-radius: 20px!important;
    color:black;
}

.droplist.inner{
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 0;
    width:100%!important;
}

.dropdown .item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
}

.dropdown .item h2{
    font-weight: bold;
    font-size: 20px;
}

.dropdown .icon:last-child{
    background-color: white;
    border-radius: 10px;
    padding:5px;
}

.droplist{
    overflow: hidden;
    display:flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.864);
    color:white;
    padding:18px;
    font-size:1.6rem;
    box-sizing: border-box;
    border-radius: 0 25px 0 0;
    transition: all 300ms;
}

.droplist:not(.close){
    transform-origin: top;
    animation: dropgiu 400ms forwards;
}

.droplist .prodotto:first-child{
    border-radius: 20px 20px 0px 0px;
}
.droplist .prodotto:last-child{
    border-radius: 0px 0px 20px 20px;
}

.droplist.close{
    height: 0;
    padding:0;
}


@media (min-width: 768px){
    .dropdown:first-of-type{
        border-radius: 20px 0px 0px 0px;
    }
    #categories:has(:nth-child(9) ~ .close) :nth-child(9){
        border-radius: 0px 0px 0px 20px;
    }
}

@keyframes dropgiu {
    from { transform: scaleY(0.5); opacity:0; }
    to { transform: scaleY(1); opacity:1; }
}