
div.logoGrande{
    width: 100%;
    box-sizing: border-box;
    padding:40px;
    padding-bottom: 20px;
}
div.logoGrande img{
    display: block;
    width:100%;
    max-width: 300px;
    margin: auto;
    animation: logoGira 2s ease-out;
}
.banner{
    display: block;
    width:100%;
    margin:auto;
}

.video-cont video{
    display: block;
    width:90%;
    max-width: 600px;
    border-radius: 10px;
    margin:auto;
}

.video-cont{
    margin-bottom: 40px;
}

@keyframes logoGira{
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

section{
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    font-size:1.3rem;
    display: flex;
    flex-wrap: wrap;
    gap:40px;
    margin-bottom: 50px;
}

section h2{
    font-size:1.5rem;
    padding-bottom:20px;
    border-bottom:1px solid rgba(0, 0, 0, 0.4);
}
